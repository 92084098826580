import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";


const Redirect = (props) => {
  return (
    <div className="redirect">
      <h1>Oops! You seem to be lost.</h1>
      <Button component={Link} to={'/'}>Back to site</Button>
    </div>
  );
};

Redirect.propTypes = {};

export default Redirect;
