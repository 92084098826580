import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const ServiceCard = (props) => {

  return (
    <Card className="services-card" sx={{ maxWidth: 345 }}>
      <CardContent>
        <CardHeader
          avatar={props.props.icon}
          className="services-card-content"
          title={props.props.title}
          subheader={props.props.subheader}
        />
      </CardContent>
    </Card>
  );
};

ServiceCard.propTypes = {};

export default ServiceCard;
