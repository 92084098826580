import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./slider.css";
import { Autoplay, Pagination, Navigation } from "swiper";

const Slider = (props) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="swiper-slide slide-one">
          <div className="slide--wrapper">
            <div className="slide--text">
              <span className="header--label">WHAT WE DO</span>
              <h1 className="title">#1 Cooker specialist</h1>
              <p className="text">
                Get in touch with our team for your free quote.
              </p>
            </div>
            <Button
              type="form-submit"
              href="#services"
              variant="contained"
              className="main-btn light-rounded-two"
            >
              Our Services
            </Button>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide slide-two">
        <div className="slide--wrapper">
          <div className="slide--text">
            <span className="header--label">WHY CHOOSE US</span>
            <h1 className="title">Experienced engineers</h1>
            <p className="text">Our team of professionals has over 60 years of experience providing quality service.</p>
          </div>
          <Button
            type="form-submit"
            href="#contact"
            variant="contained"
            className="main-btn light-rounded-two"
          >
            Contact us
          </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

Slider.propTypes = {};

export default Slider;
