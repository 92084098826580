import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import { Paper, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { NavContext } from "../../context/NavContext";
import Form from "./Form/Form";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const callNumber = () => {};

const Contact = (props) => {
  const refPos = useRef(null);
  const { getPositions } = useContext(NavContext);
  const [contactPosition, setContactPosition, reviewPosition, setReviewPosition, areaPosition, setAreaPosition] = getPositions;

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (contactPosition !== false) handleScroll(refPos);
  }, [contactPosition]);

  return (
    <div className="contact" id="contact" ref={refPos}>
      <h1 className="section--title">Contact</h1>
      <p>
        Our dedicated team covers most of Nottinghamshire, Derbyshire and South
        Yorkshire. Get in touch.
      </p>
      <p>
        <strong>Opening hours: Monday - Saturday 8am - 6pm</strong>
      </p>

      <div className="contact-card-wrapper">
          <Item
            component={Link}
            to={"tel:+447830303318"}
            className="contact-item"
          >
            <LocalPhoneIcon color="primary" />
            <h4>+44 7830303318</h4>
            <h4 className="contact-item--last">Primary contact</h4>
          </Item>

          <Item
            component={Link}
            to={"tel:+447828937246"}
            className="contact-item"
          >
            <LocalPhoneIcon color="primary" />
            <h4>+44 7828937246</h4>
            <h4 className="contact-item--last">Secondary contact</h4>
          </Item>

          <Item
            component={Link}
            to={"mailto:info@Repairs4Ranges.co.uk"}
            className="contact-item"
          >
            <EmailIcon color="primary" />
            <h4>info@Repairs4Ranges.co.uk</h4>
            <h4 className="contact-item--last">Responses within 24 hours</h4>
          </Item>
      </div>

      <div className="contact-form-wrapper">
        <h3>Contact our team</h3>
        <p>
          At Repairs4RangeCookers we always take our customer's privacy seriously and
          never share any personal information with third parties.{" "}
          <a href="privacy-policy" style={{ color: "#007bff" }}>
            Our privacy policy
          </a>
        </p>
<Form />
      </div>
    </div>
  );
};

Contact.propTypes = {};

export default Contact;
