import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NavContext } from "../../context/NavContext";

const Thanks = (props) => {
    const {getPopup} = useContext(NavContext)
    const [showThanks, setShowThanks] = getPopup

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowThanks(false);
  };

  return (
    <div className="thanks">
      <Dialog
        open={showThanks}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Thank you for contacting Repairs 4 Range Cookers
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">You will hear back from our team shortly. A confirmation of your enquiry has been sent to your chosen email address (this may end up in your junk or spam folder)</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Thanks.propTypes = {};

export default Thanks;
