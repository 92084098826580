import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const Form = (props) => {
  return (
    <div className="form-style-6">
      <form
        name="contact-form"
        id="contact-form"
        action="https://formsubmit.co/info@repairs4ranges.co.uk"
        method="POST"
      >
        <input type="text" name="name" placeholder="Your Name*" required="true" />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          //   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          required=""
        />
        <input
          name="postcode"
          type="text"
          placeholder="Postcode*"
          title="Try adding a space"
          required="true"
        />
        <select name="enquiry" id="enquiry">
          <option value="Installation">Installation</option>
          <option value="Removal">Removal</option>
          <option value="Repair">Repair</option>
          <option value="Service">Service</option>
          <option value="Other">Other</option>
        </select>
        <textarea
          name="message"
          placeholder="Message"
          required=""
          defaultValue={""}
        />
        <Button
          type="submit"
          variant="contained"
          className="main-btn light-rounded-two"
        >
          Send Message
        </Button>
        {/* <input type="submit" value="Send Message" /> */}
            {/* hidden form items */}
    <input
      type="hidden"
      name="_next"
      defaultValue="http://repairs4ranges.co.uk/thank-you"
    />
    <input
      type="hidden"
      name="_autoresponse"
      defaultValue="Thank you for contacting Repairs4RangeCookers. One of our team will be in touch with you shortly."
    />
    <input type="hidden" name="_template" defaultValue="table" />
    <input
      type="hidden"
      name="_subject"
      defaultValue="New Website Enquiry!"
    />
    <input type="text" name="_honey" style={{ display: "none" }} />
      </form>
    </div>
  );
};

Form.propTypes = {};

export default Form;
