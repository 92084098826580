import React, { useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup } from "@mui/material";
import { Button } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import AreaAccordion from "./AreaAccordion";
import { NavContext } from "../../context/NavContext";

const Areas = ({}) => {
  const refPos = useRef(null);
  const {getPositions} = useContext(NavContext)
  const [contactPosition, setContactPosition, reviewPosition, setReviewPosition, areaPosition, setAreaPosition] = getPositions

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (areaPosition !== false) handleScroll(refPos);
  }, [areaPosition]);

  return (
    <div className="areas" id="areas" ref={refPos}>
      <h1>Areas we cover</h1>
      <p>
        Our team covers all the following regions and postcodes. If you are
        unsure whether Repairs4RangeCookers covers your address, contact our team.
      </p>
      <div className="area-google-map">

      <iframe
        title="Repairs4RangeCookers google map location, Nottinghamshire, South Yorkshire, Derbyshire"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Repairs4RangeCookers&t=&z=7&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        ></iframe>
        </div>
        <p>Still not sure if our team covers your area, <a href="#contact">get in touch</a>.</p>
      <AreaAccordion />
      
    </div>
  );
};

Areas.propTypes = {};

export default Areas;
