import React from "react";
import PropTypes from "prop-types";
import { Paper, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import image1 from "../../assets/images/contact-free-badge-2.png";
import image2 from "../../assets/images/gas-badge.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const About = (props) => {
  return (
    <div className="about" id="about">
      <h1>About us</h1>
      <p>
        Our highly experienced and qualified engineer team, all fully insured.
        Repairs4RangeCookers work with all cooker types including Gas, Electric, Dual
        Fuel and LPG.
      </p>
      {/* <div className="about-paper-wrapper">
        <Paper elevation={3} className="about-paper-item">
          <h4>Gas Safe Registered</h4>
          <h4>Fully insured</h4>
        </Paper>
        <Paper elevation={3} className="about-paper-item">
          <h4>Fully vaccinated staff</h4>
          <h4>Contact free/limited services</h4>
        </Paper>
      </div> */}

      <div className="about-paper-wrapper">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Item className="about-item" >
            <img src={image1} className="about-image" alt="Gas Safe Registered logo" />

            <h4>Gas Safe Registered</h4>
            <h4>Fully insured</h4>
          </Item>
          <Item className="about-item">
            <img src={image2} className="about-image" alt="Fully vaccinated staff imagery" />

            <h4>Fully vaccinated staff</h4>
            <h4>Contact free/limited services</h4>
          </Item>
        </Stack>
      </div>
    </div>
  );
};

About.propTypes = {};

export default About;
