import { useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Header from "./components/Header/Header";
import Services from "./components/Services/Services";
import Areas from "./components/Areas/Areas";
import "./styles/index.scss";
import BottomNav from "./components/Navigation/BottomNav";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Data from "./pages/Data";
import Redirect from "./pages/Redirect";
import Reviews from "./components/Reviews/Reviews";
import { NavContext } from "./context/NavContext";
import NavContextProvider from "./context/NavContextProvider";
import Form from "./components/Contact/Form/Form.jsx";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: "#998c2a",
      main: "#dbc83d",
      dark: "#e3d363",
      contrastText: "#fff",
    },
    secondary: {
      light: "#2d2d2d",
      main: "#414141",
      dark: "#676767",
      contrastText: "#000",
    },
  },
});

function App() {
  // useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }),[]);

  return (
    <NavContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/thank-you" element={<Home />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          {/* <Route exact path="/request-my-data" element={<Data />} /> */}
          <Route exact path="/404" element={<Redirect />} />
          <Route exact path="*" element={<Navigate to="/404" />} />
        </Routes>
      </ThemeProvider>
    </NavContextProvider>
  );
}

export default App;
