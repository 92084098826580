import React, { useState, useRef } from "react";
import { NavContext } from "./NavContext";

function NavContextProvider({ children }) {
  const [areaPosition, setAreaPosition] = useState(false);
  const [reviewPosition, setReviewPosition] = useState(false);
  const [contactPosition, setContactPosition] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [homePosition, setHomePosition] = useState(false);

  const positions = {
    getPositions: [
      areaPosition,
      setAreaPosition,
      reviewPosition,
      setReviewPosition,
      contactPosition,
      setContactPosition,
      homePosition, setHomePosition
    ],
    getPopup: [
      showThanks, setShowThanks
    ]
  };

  return (
    <NavContext.Provider value={positions}>{children}</NavContext.Provider>
  );
}

export default NavContextProvider;
