import React, { useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import TopNav from "../Navigation/TopNav";
import Slider from "./Slider";
import { NavContext } from "../../context/NavContext";

const Header = (props) => {
  const refPos = useRef(null);
  const { getPositions } = useContext(NavContext);
  const [
    contactPosition,
    setContactPosition,
    reviewPosition,
    setReviewPosition,
    areaPosition,
    setAreaPosition,
    homePosition, setHomePosition
  ] = getPositions;

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  
  useEffect(() => {
    if (homePosition !== false) handleScroll(refPos);
  }, [homePosition]);

  return (
    <div className="header" id="home" ref={refPos}>
      <TopNav />
      <Slider />
    </div>
  );
};

Header.propTypes = {};

export default Header;
