import React from "react";
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <section className="footer footer-area footer-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {/* <div className="footer-logo text-center">
            <a className="mt-30" href="index.html">
              <img
                src="assets/images/icons/repairs4ranges-logo-cooker-white.svg"
                alt="Logo"
              />
            </a>
          </div> */}

            <ul className="social text-center mt-35">
              <li>
                <a
                  href="https://www.facebook.com/Repairs4RangeCookers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lni lni-facebook-oval" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Repairs4Ranges/@53.1454479,-1.9590495,8z/data=!3m1!4b1!4m5!3m4!1s0x4879bd8e0fdafa93:0x917309920e310005!8m2!3d53.1507107!4d-0.8380867"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lni lni-google" />
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/447830303318"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lni lni-whatsapp" />
                </a>
              </li>
            </ul>
            {/* social */}
            <div className="footer-support text-center">
              <p className="text">
                <a href="tel:+447830303318">+44 7830303318</a>
              </p>
              <p className="text">
                <a href="tel:+447828937246">+44 7828937246</a>
              </p>
              <p className="text">
                <a href="mailto:info@Repairs4Ranges.co.uk">
                  info@Repairs4Ranges.co.uk
                </a>
              </p>
            </div>
            <div className="copyright text-center mt-35">
              <p className="text">
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
              <p className="text">Designed by <a href="https://uideck.com" rel="nofollow">UIdeck</a> and Built-with<a rel="nofollow" href="https://ayroui.com"> Ayro UI</a>
              </p>
            </div>
            {/*  copyright */}
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

Footer.propTypes = {};

export default Footer;
