import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Header from "../components/Header/Header";
import Services from "../components/Services/Services";
import Areas from "../components/Areas/Areas";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import BottomNav from "../components/Navigation/BottomNav";
import Footer from "../components/Navigation/Footer";
import Reviews from "../components/Reviews/Reviews";
import Thanks from "../components/Thanks/Thanks";
import { NavContext } from "../context/NavContext";

const Home = () => {
  // useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }),[]);
  const { getPopup } = useContext(NavContext);
  const [showThanks, setShowThanks] = getPopup;

  useEffect(() => {
    if (window.location.pathname === "/thank-you") setShowThanks(true)
  }, []);

  return (
    <>
      <Thanks />
      <Header />
      <Services />
      <Areas />
      <About />
      <Reviews />
      <Contact />
      <Footer />
      <BottomNav />
    </>
  );
};

Home.propTypes = {};

export default Home;
