import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { NavContext } from "../../context/NavContext";
import { ElfsightWidget } from "react-elfsight-widget";

const Reviews = ({}) => {
  const refPos = useRef(null);
  const { getPositions } = useContext(NavContext);
  const [contactPosition, setContactPosition, reviewPosition, setReviewPosition, areaPosition, setAreaPosition] = getPositions;

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (reviewPosition !== false) handleScroll(refPos);
  }, [reviewPosition]);

  return (
    <div className="reviews" id="reviews" ref={refPos}>
      <h1>What our customers say</h1>
      <p>Don't just take our word for it.</p>
      <br />
      <ElfsightWidget widgetId="07762dae-680b-4a68-bd6d-cc63265c9d6a" />
      {/* <iframe
        className="responsive-iframe"
        title="Repairs 4 Range Cookers Google Reviews"
        src="https://widget.taggbox.com/132318"
        style={{ width: "100%", height: "380px", border: "none" }}
      ></iframe> */}

    </div>
  );
};

Reviews.propTypes = {};

export default Reviews;
