import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const content = [
  {
    id: 1,
    title: "Cheshire",
    area: "Alderley Edge, Sandbach, Altrincham, Bowden, Birkenhead, Chester, Congleton, Crewe, Ellesmere Port, Frodsham, Knutsford, Lymm, Middlewich, Nantwich, Northwich, Prestbury, Runcorn, Macclesfield, Sale, Stockport, Tarporley, Timperley, Wallasey, Warrington, Widnes, Winsford",
    postcode: "CH CW SK WA",
  },
  {
    id: 2,
    title: "Derbyshire, Lincolnshire, Nottinghamshire",
    area: "Ashbourne, Buxton, Chesterfield, Derby, Mansfield, Matlock, Sheffield",
    postcode:
      "DE55, DN, S, NG15, 16, 17, 18, 19, 20, 21, 22 NG1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 23, 24, 25 DE5, 7, 72, 74, 75 LN5, LN6, PE",
  },
  {
    id: 3,
    title: "Lancashire",
    area: "Bolton, Leigh, Liverpool, Manchester, Oldham, St Helens, Warrington, Wigan",
    postcode: "BL L M OL WA WN",
  },
  {
    id: 4,
    title: "North Wales",
    area: "Abergele, Bangor, Bangor, Buckley, Connahs Quay, Conway, Flint, Mold, Hollywell, Llandudno, Northop Hall, Prestatyn, Rhyl, St Asaph, Wrexham",
    postcode: "CH, LL 11-19, 22, 28-34",
  },
  {
    id: 5,
    title: "Staffordshire",
    area: "Stafford, Stoke-on-Trent",
    postcode: "ST",
  },
  {
    id: 6,
    title: "Yorkshire",
    area: "Bradford, Halifax, Wakefield, Harrogate, Huddersfield, Leeds, South Yorkshire, West Yorkshire",
    postcode: "BD HD HG HX LS S",
  },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AreaAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(2);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {content.map((item, i) => (
        <Accordion
          key={i}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className="areas-expanded-content">
            <Typography>
              <strong>Covering: </strong>
              {item.area}
            </Typography>
            <Typography>
              <strong>Postcodes: </strong>
              {item.postcode}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

AreaAccordion.propTypes = {};

export default AreaAccordion;
