import React, { useState, useRef, useContext } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { NavContext } from "../../context/NavContext";

const BottomNav = () => {
  const [value, setValue] = useState(0);
  const { getPositions } = useContext(NavContext);
  const [contactPosition, setContactPosition, reviewPosition, setReviewPosition, areaPosition, setAreaPosition] = getPositions;

  const handleScrollTo = (id) => {
    // Smooth scroll to the specified component
    scroll.scrollTo({
      duration: 500, // Duration of the animation
      smooth: "easeInOutQuart", // Easing function
      containerId: "root", // ID of the container to scroll
      offset: -50, // Offset to scroll above the component
      spy: true, // Enable active class based on scroll position
      activeClass: "active", // Adjust the active class name if necessary
      hashSpy: true, // Enable active class based on hash value
      to: id, // ID of the component to scroll to
    });
  };

  return (
    <Box sx={{ pb: 7 }}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Locations"
            icon={<LocationOnIcon />}
            component={Link}
            to="/#areas"
            onClick={() => setAreaPosition((prev) => !prev)}
          />
          <BottomNavigationAction
            label="Reviews"
            icon={<ReviewsIcon />}
            component={Link}
            to="/#reviews"
            onClick={() => setReviewPosition((prev) => !prev)}
            />

            <BottomNavigationAction
              label="Contact"
              icon={<LocalPhoneIcon />}
              component={Link}
              to="/#contact"
              onClick={() => setContactPosition((prev) => !prev)}
              />

        </BottomNavigation>
      </Paper>
    </Box>
  );
};

BottomNav.propTypes = {};

export default BottomNav;
