import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";
import ServiceCard from "./ServiceCard";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HandymanIcon from "@mui/icons-material/Handyman";

// Get image paths function
const getImagePaths = (directory) => {
  let images = [];
  directory.keys().map((item, index) => images.push(item.replace("./", "")));
  return images;
};
const directory = require.context("../../assets/brand-slider/", false, /\.(png|jpe?g|svg)$/);
let imagePaths = getImagePaths(directory);

let images = [];
imagePaths.map((path) => images.push(require("../../assets/brand-slider/" + path)));

const Services = (props) => {
  const cardContent = [
    {
      title: "Repairs",
      subheader:
        "Our team are qualified to repair all cooker types and work with a range of brands.",
      icon: <HandymanIcon color="primary" />,
    },
    {
      title: "Installation",
      subheader:
        "We can install any cooker or range cooker, just get in touch.",
      icon: <LocalShippingIcon color="primary" />,
    },
    {
      title: "Servicing",
      subheader:
        "We help customers keep their cookers in top form by offering servicing and maintenance.",
      icon: <HomeRepairServiceIcon color="primary" />,
    },
  ];
  return (
    <div className="services" id="services">
      <h1>Our Services</h1>
      <p>
        Our dedicated team has over 65 years of experience working with cooking
        appliances and providing excellent customer service.
      </p>
      <div className="services-card-wrapper">
        <ServiceCard props={cardContent[0]} />
        <ServiceCard props={cardContent[1]} />
        <ServiceCard props={cardContent[2]} />
      </div>
      <h2>Brands we specialise in</h2>
      <p>
        AGA, BEKO, Belling, Bertazzoni, Britannia , Cannon, FALCON, Flavel,
        Godin, Hotpoint , Ilve, La Cornue , Lacanche, Lamona, Leisure, Mercury,
        New World , Rangemaster and Stoves.
      </p>
      <div className="brand-slider">
        <div className="box">
          <Carousel
            useKeyboardArrows="true"
            autoPlay="true"
            interval="1000"
            transitionTime={310}
            swipeable="true"
            width="50%"
            infiniteLoop="true"
            showThumbs="false"
            showStatus="false"
            showIndicators="false"
          >
            {images.map((URL, index) => (
              <div className="slide" key={index}>
                <img alt="sample_file" src={URL} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

Services.propTypes = {};

export default Services;
